import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: "/",
    name: "index", //首页
    component: () => import("../views/index/index.vue"),
  },
  {
    path: "/brief",
    name: "brief", //关于我们-公司简介
    component: () => import("../views/about/brief.vue"),
  },
  {
    path: "/develop",
    name: "develop", //关于我们-发展历程
    component: () => import("../views/about/develop.vue"),
  },
  {
    path: "/agency",
    name: "agency", //关于我们-组织机构
    component: () => import("../views/about/agency.vue"),
  },
  {
    path: "/honor",
    name: "honor", //关于我们-公司荣誉
    component: () => import("../views/about/honor.vue"),
  },
  {
    path: "/partyBuild",
    name: "partyBuild", //党的建设-
    component: () => import("../views/partyBuild/partyBuild.vue"),
  },
  {
    path: "/partyDetails",
    name: "partyDetails", //党的建设-详情页
    component: () => import("../views/partyBuild/partyDetails.vue"),
  },
  {
    path: "/groupNews",
    name: "groupNews", //新闻中心-集团要闻
    component: () => import("../views/news/groupNews.vue"),
  },
  {
    path: "/companyNewss",
    name: "companyNewss", //新闻中心-集团要闻
    component: () => import("../views/news/groupNewss.vue"),
  },
  {
    path: "/fiveDynamics",
    name: "fiveDynamics", //新闻中心-五院动态
    component: () => import("../views/news/fiveDynamics.vue"),
  },
  {
    path: "/companyNews",
    name: "companyNews", //新闻中心-公司要闻
    component: () => import("../views/news/companyNews.vue"),
  },
  {
    path: "/submission",
    name: "submission", //新闻中心-投稿入口
    component: () => import("../views/news/submission.vue"),
  },
  {
    path: "/product",
    name: "product", //公司产品-列表-微系统与控制部组件
    component: () => import("../views/product/product.vue"),
  },
  {
    path: "/products",
    name: "products", //公司产品-列表-核工业智能装备
    component: () => import("../views/product/products.vue"),
  },
  {
    path: "/product1",
    name: "product1", //公司产品-列表-核工业智能装备
    component: () => import("../views/product/product1.vue"),
  },
  {
    path: "/product2",
    name: "product2", //公司产品-列表-智能测试与仿真系统
    component: () => import("../views/product/product2.vue"),
  },
  {
    path: "/product3",
    name: "product3", //公司产品-列表-铁路运行安全检测系统
    component: () => import("../views/product/product3.vue"),
  },
  {
    path: "/productDetails",
    name: "productDetails", //公司产品-详情页
    component: () => import("../views/product/productDetails.vue"),
  },
  {
    path: "/notice",
    name: "notice", //投资者关系-公司公告
    component: () => import("../views/investor/notice.vue"),
  },
  {
    path: "/administer",
    name: "administer", //投资者关系-公司治理
    component: () => import("../views/investor/administer.vue"),
  },
  {
    path: "/relationship",
    name: "relationship", //投资者关系-投资者互动
    component: () => import("../views/investor/relationship.vue"),
  },
  {
    path: "/demeanor",
    name: "demeanor", //人力资源-大家风范
    component: () => import("../views/resources/demeanor.vue"),
  },
  {
    path: "/joinus",
    name: "joinus", //人力资源-加入我们
    component: () => import("../views/resources/joinus.vue"),
  },
  {
    path: "/recruitDetails",
    name: "recruitDetails", //人力资源-招聘详情页
    component: () => import("../views/resources/recruitDetails.vue"),
  },
  {
    path: "/contactUs",
    name: "contactUs", //联系我们-联系我们
    component: () => import("../views/contactUs/contactUs.vue"),
  },
  {
    path: "/search",
    name: "search", //搜索结果页
    component: () => import("../views/search/search.vue"),
  },

  {
    path: "/homepage",
    name: "homepage", //子栏目首页
    component: () => import("../subsidiary/homepage/homepage.vue"),
  },

  {
    path: "/zabout",
    name: "zabout", //子栏目公司概况
    component: () => import("../subsidiary/about/about.vue"),
  },
  {
    path: "/znews",
    name: "znews", //子栏目公司新闻
    component: () => import("../subsidiary/new/new.vue"),
  },
  {
    path: "/znewdetails",
    name: "znewdetails", //子栏目公司新闻详情
    component: () => import("../subsidiary/new/newdetails.vue"),
  },
  {
    path: "/zproduct",
    name: "zproduct", //子栏目公司产品
    component: () => import("../subsidiary/product/product.vue"),
  },
  {
    path: "/zproductdetails",
    name: "zproductdetails", //子栏目公司产品详情
    component: () => import("../subsidiary/product/productdetails.vue"),
  },

  // {
  //   path: '/home',
  //   name: 'home',
  //   component: HomeView
  // },

  // {
  //   path: '/information',
  //   name: 'Information',//个人中心_注册资料管理
  //   component: () => import('../views/personal/information.vue')
  // },
];

const router = new VueRouter({
  routes,
});
/** 解决跳转重复路由报错问题 */
const routerPush = router.push;
router.push = (path) => {
  // 判断下当前路由是否就是要跳转的路由
  if (router.currentRoute.fullPath.includes(path)) return;
  return routerPush.call(router, path);
};

export default router;
