<template>

  <div id="app">
    <!-- <router-view v-if="isReactive"></router-view> -->
    <router-view v-if="isReactive" :key="$route.path + $route.query.t"></router-view>
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav>
    <router-view/> -->
  </div>
</template>
<script>
  export default {
    // components: {
    //   Swiper
    //     },
    //     name: "Swiper",
    //     data() {
    //         return {
    //             menushow:false,
    //         };
    // },
    provide() {
      return {
        reload: this.reload,
      };
    },
    data() {
      return {
        token: "",
        // routerState: true,
        isReactive: true,
      };
    },
    created() {
      this.goOrther()
    },
    methods: {
      reload() {
        this.isReactive = false;
        this.$nextTick(() => {
          this.isReactive = true;
          // console.log("刷新");
        });
      },
      goOrther() {
        var is_mobi =
          navigator.userAgent
            .toLowerCase()
            .match(
              /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            ) != null;
        if (is_mobi) {
          // 如果是移动端就跳转到移动端地址
          let params = new URL(window.location.href).hash;
          let test = window.location.href;
          let arr = test.split(params);
          let baseurl = arr[0]+"aeromove/" + params;   // 测试接口 
          window.location.href = baseurl;
        } else {
          
        }
      },
    },
    mounted() {
      cx();
      window.addEventListener("resize", function () {
        cx();
      });
      function cx() {
        var Wth = document.documentElement.clientWidth;
        var app = document.querySelector("#app")
        if (Wth < 1450) {
          if (navigator.userAgent.indexOf("Firefox") != -1) {
            //判断当前浏览器是否是火狐浏览器
            // app.style.width = "1450px";
            // document.documentElement.style.transform =
            //   "scale(" + Wth / 1450 + ")";
            // // document.body.style.overflow = "hidden";
            // document.documentElement.style.transformOrigin = "left top 0px";
          } else {
            document.documentElement.style.zoom = (Wth / 1450) * 100 + "%";
          }
        } else {
          app.style.width = "100%"
          // document.documentElement.style.zoom = "100%";
        }
      }
    },
  };
</script>
<style lang="less">
  @import "./assets/css/gstatic.css";
  @import "../node_modules/swiper/css/swiper.css";
  @import "./assets/css/animate.css";

  * {
    margin: 0;
    padding: 0;
    font-family: "Microsoft YaHei", "Arial", "Verdana";
  }

  #app {
    position: relative;

  }

  .container {
    width: 100%;
    /* min-width: 1450px; */
    /* overflow: hidden;
    overflow-x: auto;
    overflow-y: ; */
  }

  .main {
    width: 1400px;
    margin: auto;
  }

  a {
    text-decoration: none;
  }

  @media only screen and (max-width: 767px) {
    .hidden-xs-only {
      display: none;
    }
  }

  @media only screen and (min-width: 768px) {
    .hidden-sm-and-up {
      display: none !important;
    }
  }

  /* 二级 */
  .secondary {
    width: 100%;
    position: relative;
  }

  .secondary_img {
    width: 100%;
    height: 100%;
    display: block;
  }

  .secondary_p {
    font-size: 45px;
    color: #fff;
    position: absolute;
    z-index: 1;
    color: #fff;
    top: 34%;
    width: 1400px;
    left: calc(50% - 700px);
    margin-left: 5px;
  }

  .secondary_nav {
    width: 100%;
    position: relative;
    box-shadow: 4px 0px 18px 1px rgba(167, 167, 167, .37);
  }

  .secondary_nav::after {
    position: absolute;
    content: "";
    width: 100%;
  }

  .secondary_cont {
    display: flex;
    padding: 30px 0 28px 0;
  }

  .dary_list {
    width: 213px;
    height: 77px;
    font-size: 22px;
    color: #525252;
    line-height: 77px;
    background-color: #f3f3f3;
    box-shadow: 3px 0px 6px 2px #e5e4e4;
    margin-right: 29px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
  }

  .dary_list:last-child {
    margin-right: 0;
  }

  .dary_list.active,
  .dary_list:hover {
    color: #fff;
    background: linear-gradient(to right, #2d629a, #16a7da);
  }

  .bread {
    display: flex;
    /* height: 135px; */
    height: 95px;
  }

  .crumbs {
    display: flex;
    align-items: center;
  }

  .crup {
    font-size: 20px;
    color: #000018;
  }

  .home {
    width: 19px;
    height: 17px;
  }

  .breadjt {
    width: 8px;
    height: 12px;
    margin-left: 12px;
    margin-right: 8px;
  }

  .secondary_cont {
    margin-left: auto;
    display: none;
  }
</style>