import Vue from "vue";
import MetaInfo from "vue-meta-info";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "@/assets/css/global.css";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(MetaInfo);
Vue.use(ElementUI);
Vue.config.productionTip = false;

//百度地图
import BaiduMap from "vue-baidu-map";
Vue.use(BaiduMap, {
  ak: "1WaBYk1SPXxLO4lToT6QbSoq3xf3RoIA",
});

import configURL from "../public/config.json";
import axios from "axios";
Vue.prototype.$axios = axios;

// console.log(configURL)
Vue.prototype.Baseurl = configURL.Baseurl;
Vue.prototype.Tupianurl = "https://api.asiet.net/";
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
